import React from 'react';
import Loadable from 'react-loadable';
import { graphql } from 'gatsby';
import {
  AccessibilityAuditComponentResultsSection,
  IconItemList,
  ItemsListIndexPage,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Section,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';
import pageHeroData from '../../../data/pages/components.yml';
import { SearchInput } from '@hudl/uniform-ui-components';

const Headline = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Headline;
    return <Component {...props} />;
  },
});

const IndexPage = ({ data }) => {
  const [searchValue, setSearchValue] = React.useState('');
  const searchRef = React.useRef();

  const clearSearch = () => {
    searchRef.current.value = '';
    setSearchValue('');
  };

  const handleSearch = () => {
    setSearchValue(searchRef.current.value || '');
  };

  return (
    <PageWithSubNav
      pageType="design"
      title="Icons Overview"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Icons" tierThree="Overview" />
      <V5Notice
        componentName="Icon"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-icon--docs"
      />
      <PageNavigation
        links={['All Icons', 'Icon Categories', 'Accessibility']}
      />

      <Section>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          className="uni-margin--one-and-half--bottom">
          <Headline level="2">
            <span id="All Icons">All Icons</span>
          </Headline>
          <SearchInput
            ref={searchRef}
            onChange={handleSearch}
            onDismissClick={clearSearch}
            placeholder="Search for an icon..."
          />
        </div>
        <IconItemList data={data} searchValue={searchValue} />
      </Section>

      <Section title="Icon Categories">
        <ItemsListIndexPage
          data={data}
          tierTwo="icons"
          listType="visual"
          listLayout="3"
        />
      </Section>

      <AccessibilityAuditComponentResultsSection componentName="Icons" />
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query iconsDesignOverviewQuery(
    $tierOne: String = "Components"
    $title: String = "All"
  ) {
    ...indexList
    ...iconDataDesign
  }
`;
